//eslint-disable react/jsx-props-no-spreading react/jsx-sort-props
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import useAccountQuery from 'queries/useAccountQuery';
import useTeamStoreUrlQuery from 'queries/useTeamStoreUrlQuery';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { validateTeamStoreAccount } from '@activebrands/core-web/state/auth/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import Form from 'components/Form';
import OverlayContent from 'components/Header/OverlayContent';
import StateIcon from 'components/icons/StateIcon';
import Paragraph from 'components/text/Paragraph';

export const SIGN_IN = 'sign-in';
export const SIGN_UP_STEP_ONE = 'sign-up-step-one';
export const SIGN_UP_STEP_TWO = 'sign-up-step-two';
export const RESET_PASSWORD = 'reset-password';
export const UPDATE_PASSWORD = 'update-password';

const Wrapper = styled('div', {
    background: 'var(--color-bg-account)',
    margin: '4px',
    position: 'relative',
    zIndex: 0,
    width: 'var(--width-overlays)',
    height: `calc(var(--vh) - var(--height-header) - 12px)`,

    [media.min['tablet.sm']]: {
        margin: '8px 4px',
    },
});

const parseError = error => {
    if (error === 'Member already in marketing list') {
        return fm('You have already validated your Team Store account.');
    }

    return fm(
        'Could not validate your Team Store account. Please make sure you have entered the correct Team ID and Team password.'
    );
};

const ValidateTeamAccountOverlay = () => {
    const [css] = useStyletron();
    const dispatch = useTypedDispatch();
    const { validateTeamAccountContent, validateTeamAccountTosLabel } = useAccountQuery();
    const { teamStoreCategoryPage: teamStoreCategoryPagePath } = useTeamStoreUrlQuery();

    const { isAuthenticated, isLoading: isLoadingAuth, teamValidationError, user } = useSelector(state => state.auth);
    const token = user?.token;
    const teamIdValidated = !!user?.teams[0]?.tag;
    const errors = teamValidationError ? [{ error: parseError(teamValidationError) }] : [];

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (teamIdValidated) {
            overlay.close('validate-team-account');
            overlay.open('validate-team-account-success');
        }
    }, [teamIdValidated]);

    const onSubmit = async field => {
        const { teamId, teamPassword } = field;

        if (!isAuthenticated && !isLoadingAuth) {
            overlay.closeAll();
            return overlay.open('unauthenticated', { redirectUri: teamStoreCategoryPagePath });
        }

        setIsSubmitting(true);
        await dispatch(validateTeamStoreAccount({ teamId, teamPassword, token }));
        setIsSubmitting(false);
    };

    return (
        <Wrapper>
            <ScrollableBox $style={{ height: '100%' }} scrolldirection="vertical">
                <div>
                    <div
                        className={css({
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                            padding: '12px',
                            borderBottom: '1px solid var(--color-border-account)',
                        })}
                    >
                        <Paragraph fontKeys="Primary/16_100_-3">{fm('Team Store')}</Paragraph>

                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                width: '40px',
                                height: '100%',
                                cursor: 'pointer',
                                justifyContent: 'flex-end',
                            })}
                            onClick={() => overlay.close('validate-team-account')}
                        >
                            <StateIcon size="16px" state="reset" />
                        </div>
                    </div>

                    <Form
                        className={css({ padding: '24px 12px' })}
                        buttonLabel={isSubmitting ? `${fm('Loading')}...` : fm('Validate account')}
                        buttonProps={{
                            disabled: isSubmitting,
                            fontKeys: 'Primary/16_100_-3',
                            $style: {
                                width: '100%',
                                marginTop: '12px',
                                display: 'flex',
                                justifyContent: 'center',
                            },
                        }}
                        errors={errors}
                        fields={[
                            {
                                label: fm('Team ID'),
                                name: 'teamId',
                                required: true,
                                $style: {
                                    flexBasis: '100%',
                                },
                            },
                            {
                                label: fm('Team Password'),
                                name: 'teamPassword',
                                required: true,
                                $style: {
                                    flexBasis: '100%',
                                },
                            },
                            {
                                label: validateTeamAccountTosLabel,
                                name: 'confirm',
                                required: true,
                                type: 'checkbox',
                                $style: {
                                    flexBasis: '100%',
                                },
                            },
                            {
                                type: 'render',
                                render: () =>
                                    validateTeamAccountContent && (
                                        <OverlayContent
                                            content={validateTeamAccountContent}
                                            wrapperStyles={{ paddingTop: '24px' }}
                                        />
                                    ),
                            },
                        ]}
                        onSubmit={onSubmit}
                    />
                </div>
            </ScrollableBox>
        </Wrapper>
    );
};

export default ValidateTeamAccountOverlay;
